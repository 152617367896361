
import BudgetsAddressService from "@/modules/budgets-address/budgets-address-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.create(payload).then(response => {
                resolve({
                    message: "BudgetsAddress criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.update(payload.id,payload).then(response => {
                resolve({
                    message: "BudgetsAddress atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.remove(id).then(response => {
                resolve({
                    message: "BudgetsAddress removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.delete(id).then(response => {
                resolve({
                    message: "BudgetsAddress deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.get(id).then(response => {
                resolve({
                    message: "BudgetsAddress encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsAddress encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsAddressService.all().then(response => {
                resolve({
                    message: "BudgetsAddress encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsAddress",
                    success: false,
                    error
                });
            })
        })
    }
}
